export const lima = {
  uri: "wss://lima.polyswarm.network/events/?chain=side",
};

export const nu = {
  uri: "wss://nu.k.polyswarm.network/v1/events/?chain=side",
};

export const rho = {
  uri: "wss://rho.k.polyswarm.network/v1/events/?chain=side",
};
